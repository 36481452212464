html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: unset;
}

.main-container {
  // provides styling for full page width containers
  max-width: 1450px;
  width: 85%;
  margin: auto;
}

main.container {
  min-height: 1000px;
}

.shadow-box {
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#full-container {
  height: 100%;
}

.ui.page.modals.dimmer.transition.visible {
  z-index: 100000;
}

.ui.multiple.dropdown > .label {
  background-color: white;
  font-size: 12px;
  line-height: 24px;
  text-align: left;
}

.ui.selection.dropdown .menu > .item {
  font-size: 14px;
  padding: 80px;
}

.ui.search.searchbar > .ui.input.icon {
  width: 100%;
  height: 40px;
}

.ui.secondary.pointing.menu > .active.item {
  border-color: var(--halo-blue);
  font-weight: 600;
}

.ui.secondary.pointing.menu > .active.item:hover {
  border-color: var(--halo-blue);
}

.ui.secondary.pointing.menu {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.bottom.attached.segment.tab {
  border: none;
}

.ui.checkbox label::before {
  border-color: #e5e7eb;
}

.ui.checkbox label:hover::before {
  border-color: var(--halo-blue);
}

.ui.checkbox input:checked ~ label:before {
  background: var(--halo-blue);
}

.ui.checkbox input:checked ~ label:after {
  color: #fff;
  font-size: 11px;
}

.ui.toggle.checkbox input:checked ~ label::before {
  background-color: var(--halo-blue) !important;
}

.ui.toggle.checkbox label::before {
  width: 3rem;
}

.ui.toggle.checkbox input:checked ~ label::after {
  left: 1.65rem;
}

.ui.button,
.ui.menu,
.ui.input > input,
.ui.form input[type="password"] {
  font-family: "Inter", sans-serif;
}

.ui.button {
  font-weight: 600;
}

.modal {
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}

@keyframes glow {
  to {
    box-shadow: 0px 0px 10px 5px var(--halo-blue);
  }
}

.ui.icon.input > i.icon::after,
.ui.icon.input > i.icon::before {
  left: 4px;
}

.ui.container {
  max-width: 1250px !important;
}

.ui.container.narrow {
  max-width: 1000px !important;
}

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"] {
  font-family: inherit !important;
}

.ui.card > .content > .header,
.ui.cards > .card > .content > .header {
  font-family: inherit !important;
}
