// Icons

@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot");
  src: url("../../fonts/icomoon.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/icomoon.svg#icomoon") format("svg"),
    url("../../fonts/icomoon.woff") format("woff"),
    url("../../fonts/icomoon.ttf") format("truetype");
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.3em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
