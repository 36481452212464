// Mixins

@mixin main-nav {
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: $nav-tabs-font-size;
}
@mixin dotted {
  background: transparent left bottom url("../../images/dotted-bg.jpg") repeat-x;
  padding-bottom: 1em;
}
@mixin boxshadow {
  border: 0;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
}
